import { Component, OnInit, inject } from '@angular/core';
import { AgregarSegmentoComponent } from './agregar-segmento/agregar-segmento.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AlertOneButtonComponent } from '../../../common/popus/alert-one-button/alert-one-button.component';
import { BACKLOG_PARAMETRIZACION_REPOSITORY } from '../repository/backlog-parametrizacion-repository.module';


@Component({
  selector: 'visor-envio-mensaje-segmento',
  templateUrl: './envio-mensaje-segmento.component.html',
  styleUrls: ['./envio-mensaje-segmento.component.scss']
})
export class EnvioMensajeSegmentoComponent implements OnInit {
  private readonly backlogParametrizacionRepository = inject(BACKLOG_PARAMETRIZACION_REPOSITORY);

  public formEditSegment: UntypedFormGroup;
  public segmentos: any;
  public tempSegment: any;
  public saveStateSegmen: any;
  public valitadeOnlyNumber = false;
  public originalSegment: any;

  constructor(
    private _modal: NgbModal,
    private spinner: NgxSpinnerService,
    private _formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.getSegmentService();
    this.initFormSegment();
  }

  initFormSegment() {
    this.formEditSegment = this._formBuilder.group(
      {
        formEngine: new UntypedFormControl(),
        formSegmentName: new UntypedFormControl('', [
          Validators.minLength(5),
          Validators.maxLength(30)
        ]),
        formIdSegmento: new UntypedFormControl('', [
          Validators.minLength(5),
          Validators.maxLength(10)
        ])
      }
    );
  }

  get getFormControllerName() {
    return this.formEditSegment.get('formSegmentName');
  }

  get getFormControllerIdSegment() {
    return this.formEditSegment.get('formIdSegmento');
  }

  getSegmentService() {
    this.backlogParametrizacionRepository.getSegments()
      .subscribe({
        next: (res) => {
          this.segmentos = res;

          this.segmentos.forEach(segment => {
            segment.editEnable = false;
          });
        }
      })
  }

  addSegment() {
    const popUpAddSegment = this._modal.open(AgregarSegmentoComponent, { backdropClass: 'cbaf-modal-backdrop' });
    popUpAddSegment.componentInstance.segments = this.segmentos;
  }

  updateSegment(segment, active) {
    if (segment.flagActivation != active) {
      this.spinner.show();

      let oldValue = {
        flagActivation: segment.flagActivation,
        idKeySegment: segment.idKeySegment,
        segmentCode: segment.segmentCode,
        segmentDescription: segment.segmentDescription,
        updateDateTime: segment.updateDateTime
      };

      let tempSegment = {
        idKeySegment: segment.idKeySegment,
        segmentCode: segment.segmentCode,
        segmentDescription: segment.segmentDescription,
        flagActivation: active,
        updateDateTime: 0,
        segmentaValid: segment.segmentaValid,
        oldValue: JSON.stringify(oldValue)
      };

      this.backlogParametrizacionRepository.updateSegment(tempSegment)
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.getSegmentService();
          }, error: (error) => {
            this.openAlertValidation('Ocurrió un error inesperado');
            this.spinner.hide();
          }
        })
    }
  }

  editSegment(segment, index) {
    this.backlogParametrizacionRepository.getSegments()
      .subscribe({
        next: (res) => {
          this.segmentos = res;
          this.segmentos.forEach(segment => {
            segment.editEnable = false;
          });

          this.tempSegment = this.segmentos[index];
          this.originalSegment = { ...this.tempSegment }
          this.validateFormEdit();
          this.segmentos[index].editEnable = true;
          this.editIdSegment();

          this.segmentos.map((item, i) => {
            if (i != index) {
              item.editEnable = false;
            }
          });
        }
      });
  }

  validateFormEdit() {
    if (!this.tempSegment.segmentCode || this.tempSegment.segmentCode.length < 10 || this.tempSegment.segmentCode.length > 10 || this.valitadeOnlyNumber ||
      !this.tempSegment.segmentDescription || this.tempSegment.segmentDescription.length > 30 || this.tempSegment.segmentDescription.length < 10 || this.onlyLettersAndNumbers(this.tempSegment.segmentDescription)) {
      this.tempSegment.segmentaValid = false;
    } else {
      this.tempSegment.segmentaValid = true;
    }
  }

  confirmEditSegment(index) {
    let codSegmenFound = false;

    this.segmentos.map((item, i) => {
      if (item.segmentCode == this.tempSegment.segmentCode && i != index) {
        codSegmenFound = true;
      }
    });

    if (!codSegmenFound) {

      let oldValue = {
        idKeySegment: this.originalSegment.idKeySegment,
        segmentCode: this.originalSegment.segmentCode,
        segmentDescription: this.originalSegment.segmentDescription,
        flagActivation: this.originalSegment.flagActivation,
        updateDateTime: this.originalSegment.updateDateTime
      };

      let tempSegment = {
        idKeySegment: this.tempSegment.idKeySegment,
        segmentCode: this.tempSegment.segmentCode,
        segmentDescription: this.tempSegment.segmentDescription,
        flagActivation: this.tempSegment.flagActivation,
        updateDateTime: this.tempSegment.updateDateTime,
        segmentaValid: this.tempSegment.segmentaValid,
        oldValue: JSON.stringify(oldValue)
      };
      this.backlogParametrizacionRepository.updateSegment(tempSegment)
        .subscribe({
          next: (res) => {
            this.openAlertValidation("Se actualizó el segmento.");
            this.getSegmentService();
          }
        });
    } else {
      this.openAlertValidation('El ID ya se encuentra registrado, pruebe con otro ID');
    }
  }

  openAlertValidation(message) {
    const alert = this._modal.open(AlertOneButtonComponent, { backdropClass: 'cbaf-modal-backdrop' });
    alert.componentInstance.message = message;
  }

  editIdSegment() {
    if (this.onlyNumbers(this.tempSegment.segmentCode)) {
      this.valitadeOnlyNumber = false;
    } else {
      this.valitadeOnlyNumber = true;
    }
    this.validateFormEdit();
  }
  onlyLettersAndNumbers(text) {
    return /[`´¨~¬°¡!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?¿]/.test(text);
  }

  onlyNumbers(text) {
    return /^\d*$/.test(text);
  }

  cancelUpdate(segment, i) {
    this.segmentos[i].editEnable = false;
    this.getSegmentService();
  }
}
